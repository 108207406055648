const LogisticManagementOrdersList = async () => import('../../pages/logistic-management/orders/List').then((module) => ({ Component: module.LogisticManagementOrdersList }));

const getLogisticManagementRouter = (_user, _features) => [
    {
        path: '/logistic-management/orders/',
        lazy: LogisticManagementOrdersList
    }
];

export { getLogisticManagementRouter };
