const envPrefix = import.meta.env?.VITE_SERVICE_NAME;

const getEnvironmentVariableRaw = (name, defaultValue) => {
    if (typeof window !== 'undefined' && window?._spacefill_env_?.[name] !== undefined) {
        return window?._spacefill_env_?.[name];
    }
    return defaultValue;
};

const isBooleanEnvVariableEnabled = (envVariable) => {
    return envVariable === '1' || envVariable === 'true' || envVariable === true || envVariable === 1;
};

const adminEnv = {
    envPrefix: envPrefix,
    googleAPIKey: getEnvironmentVariableRaw('googleAPIKey', import.meta.env?.VITE_ADMIN_CONSOLE_GMAPS_API_KEY),
    mapboxAPIKey: getEnvironmentVariableRaw('mapboxAPIKey', import.meta.env?.VITE_ADMIN_CONSOLE_MAPBOX_API_KEY),
    graphqlApiUrl: getEnvironmentVariableRaw(
        'graphqlApiUrl',
        import.meta.env?.VITE_ADMIN_CONSOLE_GRAPHQL_API_URL || 'http://127.0.0.1:5000/graphql'
    ),
    attachmentsUrl: getEnvironmentVariableRaw(
        'attachmentsUrl',
        import.meta.env?.VITE_ADMIN_CONSOLE_GRAPHQL_ATTACHMENTS_URL || 'http://127.0.0.1:5000/attachments'
    ),
    salesforceSyncUrl: getEnvironmentVariableRaw(
        'salesforceSyncUrl',
        import.meta.env?.VITE_ADMIN_CONSOLE_SALESFORCE_SYNC_URL || 'http://127.0.0.1:9898'
    ),
    externalConsoleServiceUrl: getEnvironmentVariableRaw(
        'externalConsoleServiceUrl',
        import.meta.env?.VITE_ADMIN_CONSOLE_EXTERNAL_CONSOLE_URL
    ),
    metabaseDashboardsEnabled: isBooleanEnvVariableEnabled(
        getEnvironmentVariableRaw(
            'metabaseDashboardsEnabled',
            import.meta.env?.VITE_ADMIN_CONSOLE_METABASE_DASHBOARDS_ENABLED
        )
    ),
    mockMapboxAxiosRequestEnabled: isBooleanEnvVariableEnabled(
        getEnvironmentVariableRaw(
            'mockMapboxAxiosRequestEnabled',
            import.meta.env?.VITE_ADMIN_CONSOLE_MOCK_AXIOS_MAPBOX_ENABLED
        )
    ),
    mockLocalFrontAxiosRequestEnabled: isBooleanEnvVariableEnabled(
        getEnvironmentVariableRaw(
            'mockLocalFrontAxiosRequestEnabled',
            import.meta.env?.VITE_ADMIN_CONSOLE_MOCK_AXIOS_LOCAL_FRONT_ENABLED
        )
    ),
    mockGoogleMapsEnabled: isBooleanEnvVariableEnabled(
        getEnvironmentVariableRaw('mockGoogleMapsEnabled', import.meta.env?.VITE_ADMIN_CONSOLE_MOCK_GOOGLE_MAPS_ENABLED)
    ),
    featureTogglingEnabled: isBooleanEnvVariableEnabled(
        getEnvironmentVariableRaw(
            'featureTogglingEnabled',
            import.meta.env?.VITE_ADMIN_CONSOLE_FEATURE_TOGGLING_ENABLED
        )
    ),
    enabledFeatures: getEnvironmentVariableRaw(
        'enabledFeatures',
        import.meta.env?.VITE_ADMIN_CONSOLE_FEATURE_TOGGLING_ENABLED_FEATURES
    ),
    apolloDevtoolsEnabled: isBooleanEnvVariableEnabled(
        getEnvironmentVariableRaw('apolloDevtoolsEnabled', import.meta.env?.VITE_ADMIN_CONSOLE_APOLLO_DEVTOOLS_ENABLED)
    ),
    dropAllLogisticManagementDataButtonEnabled: isBooleanEnvVariableEnabled(
        getEnvironmentVariableRaw(
            'dropAllLogisticManagementDataButtonEnabled',
            import.meta.env?.VITE_ADMIN_CONSOLE_DROP_ALL_LOGISTIC_MANAGEMENT_DATA_BUTTON_ENABLED
        )
    ),
    customColorPaletteFeatureEnabled: isBooleanEnvVariableEnabled(
        getEnvironmentVariableRaw(
            'customColorPaletteFeatureEnabled',
            import.meta.env?.VITE_ADMIN_CONSOLE_CUSTOM_COLOR_PALETTE_FEATURE_ENABLED
        )
    ),
    backendUrl: getEnvironmentVariableRaw('backendUrl', import.meta.env?.VITE_BACKEND_URL || 'http://127.0.0.1:3000'),
};

const externalEnv = {
    envPrefix: envPrefix,
    googleAPIKey: getEnvironmentVariableRaw('googleAPIKey', import.meta.env?.VITE_EXTERNAL_CONSOLE_GMAPS_API_KEY),
    mapboxAPIKey: getEnvironmentVariableRaw('mapboxAPIKey', import.meta.env?.VITE_EXTERNAL_CONSOLE_MAPBOX_API_KEY),
    graphqlApiUrl: getEnvironmentVariableRaw(
        'graphqlApiUrl',
        import.meta.env?.VITE_EXTERNAL_CONSOLE_GRAPHQL_API_URL || 'http://127.0.0.1:5000/graphql'
    ),
    backendUrl: getEnvironmentVariableRaw(
        'backendUrl',
        import.meta.env?.VITE_EXTERNAL_CONSOLE_BACKEND_URL || 'http://127.0.0.1:3000'
    ),
    attachmentsUrl: getEnvironmentVariableRaw(
        'attachmentsUrl',
        import.meta.env?.VITE_EXTERNAL_CONSOLE_GRAPHQL_ATTACHMENTS_URL || 'http://127.0.0.1:5000/attachments'
    ),
    gibbonPdfApiUrl: getEnvironmentVariableRaw(
        'gibbonPdfApiUrl',
        import.meta.env?.VITE_EXTERNAL_CONSOLE_GIBBON_PDF_API_URL || 'http://127.0.0.1:5003'
    ),
    beamerId: getEnvironmentVariableRaw('beamerId', import.meta.env?.VITE_EXTERNAL_CONSOLE_BEAMER_ID),
    mockMapboxAxiosRequestEnabled: isBooleanEnvVariableEnabled(
        getEnvironmentVariableRaw(
            'mockMapboxAxiosRequestEnabled',
            import.meta.env?.VITE_EXTERNAL_CONSOLE_MOCK_AXIOS_MAPBOX_ENABLED
        )
    ),
    mockLocalFrontAxiosRequestEnabled: isBooleanEnvVariableEnabled(
        getEnvironmentVariableRaw(
            'mockLocalFrontAxiosRequestEnabled',
            import.meta.env?.VITE_EXTERNAL_CONSOLE_MOCK_AXIOS_LOCAL_FRONT_ENABLED
        )
    ),
    mockGoogleMapsEnabled: isBooleanEnvVariableEnabled(
        getEnvironmentVariableRaw(
            'mockGoogleMapsEnabled',
            import.meta.env?.VITE_EXTERNAL_CONSOLE_MOCK_GOOGLE_MAPS_ENABLED
        )
    ),
    featureTogglingEnabled: isBooleanEnvVariableEnabled(
        getEnvironmentVariableRaw(
            'featureTogglingEnabled',
            import.meta.env?.VITE_EXTERNAL_CONSOLE_FEATURE_TOGGLING_ENABLED
        )
    ),
    enabledFeatures: getEnvironmentVariableRaw(
        'enabledFeatures',
        import.meta.env?.VITE_EXTERNAL_CONSOLE_FEATURE_TOGGLING_ENABLED_FEATURES
    ),
    apolloDevtoolsEnabled: isBooleanEnvVariableEnabled(
        getEnvironmentVariableRaw(
            'apolloDevtoolsEnabled',
            import.meta.env?.VITE_EXTERNAL_CONSOLE_APOLLO_DEVTOOLS_ENABLED
        )
    ),
    customColorPaletteFeatureEnabled: isBooleanEnvVariableEnabled(
        getEnvironmentVariableRaw(
            'customColorPaletteFeatureEnabled',
            import.meta.env?.VITE_EXTERNAL_CONSOLE_CUSTOM_COLOR_PALETTE_FEATURE_ENABLED
        )
    ),
    externalConsoleServiceUrl: getEnvironmentVariableRaw(
        'externalConsoleServiceUrl',
        import.meta.env?.VITE_ADMIN_CONSOLE_EXTERNAL_CONSOLE_URL
    ),
};

const storybookEnv = {
    envPrefix: envPrefix,
    googleAPIKey: getEnvironmentVariableRaw('googleAPIKey', import.meta.env?.VITE_UIKIT_GMAPS_API_KEY),
};

const getEnv = () => {
    let state = {};

    switch (envPrefix) {
        case 'ADMIN-CONSOLE':
            state = adminEnv;
            break;
        case 'EXTERNAL-CONSOLE':
            state = externalEnv;
            break;
        default:
            state = storybookEnv;
    }

    return state;
};

const getEnvironmentVariable = (variable) => {
    switch (envPrefix) {
        case 'ADMIN-CONSOLE':
            return adminEnv?.[variable];
        case 'EXTERNAL-CONSOLE':
            return externalEnv?.[variable];
        default:
            return storybookEnv?.[variable];
    }
};

const setEnvironmentVariable = (variable, value) => {
    switch (envPrefix) {
        case 'ADMIN-CONSOLE':
            adminEnv[variable] = value;
            break;
        case 'EXTERNAL-CONSOLE':
            externalEnv[variable] = value;
            break;
        default:
            storybookEnv[variable] = value;
            break;
    }
};

export { getEnv, getEnvironmentVariable, setEnvironmentVariable };
