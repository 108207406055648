import React from 'react';
import { Navigate } from 'react-router-dom';

const EdiList = async () => import('../../pages/edi/List').then((module) => ({ Component: module.EdiList }));
const EdiEdit = async () => import('../../pages/edi/Edit').then((module) => ({ Component: module.EdiEdit }));
const EdiNew = async () => import('../../pages/edi/New').then((module) => ({ Component: module.EdiNew }));

const getEdiRouter = (_user, _features) => [
    {
        path: '/edi/',
        lazy: EdiList
    },
    {
        path: '/edi/:apiAccessTokenId/edit',
        lazy: EdiEdit
    },
    {
        path: '/edi/new',
        lazy: EdiNew
    },
    {
        path: '/edi/:apiAccessTokenId',
        element: <Navigate to='/edi/' />
    }
];

export { getEdiRouter };
