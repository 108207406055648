import { NavigateWithParams } from '@spacefill/shared/src/utils/RouterUtils';
import React from 'react';

const LogisticProvidersList = async () => import('../../pages/logistic-providers/List').then((module) => ({ Component: module.LogisticProvidersList }));
const LogisticProvidersView = async () => import('../../pages/logistic-providers/View').then((module) => ({ Component: module.LogisticProviderView }));
const LogisticProviderWarehouseEdit = async () => import('../../pages/logistic-providers/warehouses/edit/Edit').then((module) => ({ Component: module.WarehouseEdit }));
const LogisticProviderWarehouseNew = async () => import('../../pages/logistic-providers/warehouses/New').then((module) => ({ Component: module.WarehouseNew }));
const LogisticProviderCustomerWarehouseConfigurationsEdit = async () => import('../../pages/logistic-providers/warehouses/customer-warehouse-configurations/Edit').then((module) => ({ Component: module.LogisticProviderCustomerWarehouseConfigurationsEdit }));
const LogisticProviderCustomerWarehouseConfigurationsView = async () => import('../../pages/logistic-providers/warehouses/customer-warehouse-configurations/View').then((module) => ({ Component: module.LogisticProviderCustomerWarehouseConfigurationsView }));

const getLogisticProviderRouter = (_user, _features) => [
    // Logistic Providers List
    {
        path: '/logistic-providers/',
        lazy: LogisticProvidersList,
        redirectFrom: '/logistic-providers'
    },

    // Logistic Providers View
    {
        path: '/logistic-providers/:logisticProviderId/',
        lazy: LogisticProvidersView
    },

    // Warehouses
    {
        path: '/logistic-providers/:logisticProviderId/warehouses/',
        lazy: LogisticProvidersView
    },
    {
        path: '/logistic-providers/:logisticProviderId/warehouses/new',
        lazy: LogisticProviderWarehouseNew
    },
    {
        path: '/logistic-providers/:logisticProviderId/warehouses/:warehouseId/edit',
        lazy: LogisticProviderWarehouseEdit
    },
    {
        path: '/logistic-providers/:logisticProviderId/warehouses/:warehouseId',
        element: <NavigateWithParams to={(params) => `/logistic-providers/${params.logisticProviderId}/warehouses/${params.warehouseId}/edit`} />
    },
    {
        path: '/logistic-providers/:logisticProviderId/warehouses/:warehouseId/customer-configurations/:customerWarehouseConfigurationId/edit',
        lazy: LogisticProviderCustomerWarehouseConfigurationsEdit
    },
    {
        path: '/logistic-providers/:logisticProviderId/warehouses/:warehouseId/customer-configurations/:customerWarehouseConfigurationId/',
        lazy: LogisticProviderCustomerWarehouseConfigurationsView
    },

    // Storage
    {
        path: '/logistic-providers/:logisticProviderId/customer-configurations/',
        lazy: LogisticProvidersView
    },
    {
        path: '/logistic-providers/:logisticProviderId/customer-configurations/:customerWarehouseConfigurationId/',
        lazy: LogisticProviderCustomerWarehouseConfigurationsView
    },

    //  Settings View
    {
        path: '/logistic-providers/:logisticProviderId/settings/',
        lazy: LogisticProvidersView
    },

    // Settings Edit
    {
        path: '/logistic-providers/:logisticProviderId/settings/edit',
        lazy: LogisticProvidersView
    },

    // Users
    {
        path: '/logistic-providers/:logisticProviderId/users/',
        lazy: LogisticProvidersView
    },

    // Permissions
    {
        path: '/logistic-providers/:logisticProviderId/permissions/',
        lazy: LogisticProvidersView
    }
];
export { getLogisticProviderRouter };
